
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import { useState, useEffect } from 'react';
import { Link, useHistory} from 'react-router-dom';
import './App.css';
import { showErrorMsg, showSuccessMsg } from './helpers/message';
import {  isAuthenticated } from './helpers/auth';
import { showLoading } from './helpers/Loading';
import {signin} from './api/auth'

export default function Signin() {
    let history = useHistory();
    useEffect(() => {
        if (isAuthenticated() && isAuthenticated().role === 1) {
            history.push('/admin/dashboard');
        } else if (isAuthenticated() && isAuthenticated().role === 0) {
            history.push('/');
        }

    }, [history])

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        successMsg: false,
        errorMsg: false,
        loading: false,
    })
    //data destructuration
    const { email, password, successMsg, errorMsg, loading } = formData;
    //Event handlers
    const handleChange = evt => {
        //console.log(evt);
        setFormData({
            ...formData,
            [evt.target.name]: evt.target.value,
            successMsg: '',
            errorMsg: ''
        });

    };
    const handleSubmit = evt => {
        evt.preventDefault();
        console.log(formData)

        if (isEmpty(email) || isEmpty(password)) {
            setFormData({
                ...formData, errorMsg: "All fields are required"
            })

        } else if (!isEmail(email)) {
            setFormData({
                ...formData, errorMsg: "Invalid Email"
            })
        } else {
            const { email, password } = formData;
            const data = { email, password };
            setFormData({ ...formData, loading: true })

            signin(data)
                .then((response) => {
                    console.log('success ', response);
                    setFormData({
                        email:'',
                        password:'',
                        successMsg: response.data.successMessage,
                        loading:false
                    })
                })
                .catch((err) => {
                    console.log('success', err);
                    setFormData({ ...formData, loading: false, errorMsg: err.response.data.errorMessage })
                });
        }
    };
    return (

        <div className='signin-background'>
            <div className='row vh-100 px-5'>
                <div className='col-md-5 mx-auto align-self-center' >
                    {/* {JSON.stringify(formData)} */}
                    {successMsg && showSuccessMsg(successMsg)}
                    {errorMsg && showErrorMsg(errorMsg)}
                    {loading && <div className='text-center pb-4'>{showLoading()}</div>}
                    <form onSubmit={handleSubmit}>
                        <h3 className='title'>Sign In</h3>

                        <div className="form-group" noValidate>
                            <input onChange={handleChange} value={email} name='email' type="email" className="form-control" placeholder="Enter email" />
                        </div>

                        <div className="form-group">
                            <input onChange={handleChange} value={password} name='password' type="password" className="form-control" placeholder="Enter password" />
                        </div>

                        <div className="form-group">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                            </div>
                        </div>

                        <button type="submit" className="btn-default btn-block">Submit</button>
                        <p className="forgot-password text-right">
                            Forgot <Link to='/resetPassword'>password?</Link>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    )
}

