import React from "react";
import "./notFound.scoped.css";
import { HashLink } from "react-router-hash-link";

export default function NotFound() {
  return (
    <div>
      <html>
        <body>
          <div id="error-page">
            <div className="content">
              <h2 className="header" data-text="404">
                404
              </h2>
              <h4 data-text="Opps! Page not found">
                Oups, voilà qui n'était pas prévu !
              </h4>
              <p>La page que vous recherchez semble introuvable</p>
              <div className="btns">
                <HashLink smooth to="/#home">
                  Retourner à la Page d'accueil
                </HashLink>
              </div>
            </div>
          </div>
        </body>
      </html>
    </div>
  );
}
