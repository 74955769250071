import React from "react";
import "./tab.scoped.css";
import ProductTable from "./ProductTable";
import ArticleTable from "./ArticleTable";
import AdminCarousel from "./AdminCarousel";

export default function tab() {
  return (
    <div>
      <div className="">
        <div className="center p-4 bg-white rounded shadow mb-5">
          <ul
            id="myTab"
            role="tablist"
            className="backi nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav color"
          >
            <li className="nav-item flex-sm-fill">
              <a
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                className="text-white nav-link border-0 text-uppercase font-weight-bold active"
              >
                PRODUCT
              </a>
            </li>

            <li className="nav-item flex-sm-fill">
              <a
                id="profile-tab"
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                className="nav-link text-white border-0 text-uppercase font-weight-bold"
              >
                News
              </a>
            </li>
            <li className="nav-item flex-sm-fill">
              <a
                id="contact-tab"
                data-toggle="tab"
                href="#contact"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
                className="nav-link border-0 text-white text-uppercase font-weight-bold"
              >
                Autres
              </a>
            </li>
          </ul>
          <div id="myTabContent" className="tab-content">
            <div
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
              className="tab-pane fade px-4 py-5 show active"
            >
              <ProductTable />
              {/* <AdminBody /> */}
            </div>
            <div
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
              className="tab-pane fade px-4 py-5"
            >
              <p className="text-muted mb-0">
                <ArticleTable />
              </p>
            </div>
            <div
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
              className="tab-pane fade px-4 py-5"
            >
             <AdminCarousel/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
