import { combineReducers, applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import loadingReducer from "./reducers/loadingReducers";
import messageReducer from "./reducers/messageReducers";
import categoryReducers from "./reducers/categoryReducers";
import productReducers from "./reducers/productReducers";
import articleReducers from "./reducers/articleReducers";
import carouselReducers from "./reducers/carouselReducers";

const reducer = combineReducers({
  loading: loadingReducer,
  messages: messageReducer,
  categories: categoryReducers,
  products: productReducers,
  articles: articleReducers,
  carousels: carouselReducers,
});

const initialState = {};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
