import Tab from "./Tab";
import  { useEffect } from "react";
import { Link } from "react-router-dom";
import './featured.scoped.css';
//redux
import { useDispatch } from "react-redux";
import { getCategories } from "../redux/actions/categoryActions";
import { getProducts } from "../redux/actions/productActions";
import { getArticles } from "../redux/actions/articleActions";



export function AdminDashboard() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getArticles());
  }, [dispatch]);

  /***************************************************
   * RENDER
   ***************************************************/

  return (
    <div>
       <Link
        className="m d-flex justify-content-center align-items-center font-weight-bold py-4 text-dark align-middle"
        To="#"
      >
        <p className="orange pt-6">Dashboard</p>
      </Link>
      <Tab />
    </div>
  );
}

export default AdminDashboard;
