import React from "react";
import BlogCard from "./BlogCard";
import { useSelector } from "react-redux";
import "./blogPage.scoped.css";

export default function Blogs() {
  const { articles } = useSelector((state) => state.articles);
  return (
    <div>
      <h2
        id="produit"
        style={{ paddingTop: "100px" }}
        className="m d-flex justify-content-center align-items-center font-weight-bold py-1 text-dark align-middle"
        To="#"
      >
        <h2 className="burgundy" id="produit">
          Nos
        </h2>
        <h2 className="orange pt-10">News</h2>
      </h2>

      <div id="articles">
        {articles?.map((article) => (
          <div>
            <BlogCard key={article._id} article={article} />
          </div>
        ))}
      </div>
    </div>
  );
}
