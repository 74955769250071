import React from "react";
import { Link } from "react-router-dom";
import "./service.scoped.css";
import useSound from "use-sound";
import boopSfx from "./boop.mp3";
import "./search.scoped.css";
import { HashLink } from "react-router-hash-link";

export default function Service() {
  const [play] = useSound(boopSfx);
  return (
    <>
    <h2
          className="serv d-flex justify-content-center align-items-center font-weight-bold py-1 text-dark align-middle"  id='service'
          To="#"
        >
          <h2 className="burgundy">Nos</h2>
          <h2 className="orange pt-1">Services</h2>
        </h2>
      <body>
        
        <div className="container">
          <div className="card">
            <div className="box">
              <div className="content">
                <h2>01</h2>
                <h3>Préparation Concours et Circuits </h3>
                <p>
                  MTS, Motor Technology Solution, est née d’une passion pour la
                  son fondateur qui, béat devant une superbe e9 3.0 csi Fjord
                </p>
                <HashLink smooth to="/#foot" onClick={play} To="/">
                  Contactez-nous
                </HashLink>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="box">
              <div className="content">
                <h2>02</h2>
                <h3>Restauration Complète</h3>
                <p>
                  C’est cette passion qu’MTS cherche à transmettre à sa
                  clientèle, en proposant des modèles très ciblés, des pièces
                </p>
                <HashLink smooth to="/#foot" onClick={play} To="/">
                  Contactez-nous
                </HashLink>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="box">
              <div className="content">
                <h2>03</h2>
                <h3>Recherches Ciblées</h3>
                <p>
                  L’ensemble de nos activités et prestations s’inscrit dans
                  l’histoire de la marque d’une part, ainsi que dans celles des
                  différents préparateurs qui ont
                </p>
                <HashLink smooth to="/#foot" onClick={play} To="/">
                  Contactez-nous
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}
