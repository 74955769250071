import axios from "axios";

export const createArticle = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  const response = await axios.post(
    process.env.REACT_APP_DOMAIN + "/api/article",
    data,
    config
  );

  return response;
};

export const getArticles = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  const response = await axios.get(
    process.env.REACT_APP_DOMAIN + "/api/article",
    config
  );
  return response;
};
