import {
    GET_CAROUSELS,
    GET_CAROUSEL,
    CREATE_CAROUSEL,
    DELETE_CAROUSEL,
  } from "../constants/carouselConstants";
  
  const INITIAL_STATE = {
    carousels: [],
    carousel:{}
  };
  
  const carouselReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case CREATE_CAROUSEL:
        return {
          carousels: [...state.carousels, action.payload],
        };
      case GET_CAROUSELS:
        return {
          carousels: [...action.payload],
        };
        case GET_CAROUSEL:
          return {
            carousel: action.payload,
          };
      case DELETE_CAROUSEL:
        return {
          carousels: state.carousels.filter(p => p._id !== action.payload._id),
        };
      default:
        return state;
    }
  };
  export default carouselReducer;
  