import React from "react";
import { Link } from "react-router-dom";
import "./productTable.scoped.css";
import "./Dashboard.css";
import AdminCategoryModal from "./AdminCategoryModal";
import AdminProductModal from "./AdminProductModal";
import { useDispatch } from "react-redux";
import { deleteProduct } from "../redux/actions/productActions";
import { useSelector } from "react-redux";

export default function ProductTable({ product }) {
  const { products } = useSelector((state) => state.products);
  const dispatch = useDispatch();

  /***************************************************
   * RENDER VIEW
   ***************************************************/
  return (
    <div>
      <body>
        <div className="container-xl">
          <div className="table-responsive">
            <div className="table-wrapper">
              <div className="table-title">
                <div className="row">
                  <div class="col-sm-6">
                    <h2>
                      Gérer Les <b>Produits</b>
                    </h2>
                  </div>
                  <div className="col-sm-6">
                    <Link
                      data-target="#editEmployeeModal"
                      className="btn btn-secondary"
                      data-toggle="modal"
                    >
                      <i class="fas fa-plus-circle" />{" "}
                      <span>Ajouter un Produit</span>
                    </Link>
                    <Link
                      data-target="#addEmployeeModal"
                      className="btn btn-secondary"
                      data-toggle="modal"
                    >
                      <i class="fas fa-plus-circle" />

                      <span>Ajouter une Catégorie</span>
                    </Link>
                  </div>
                </div>
              </div>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>
                      <span className="custom-checkbox">
                        <input type="checkbox" id="selectAll" />
                        <label for="selectAll" />
                      </span>
                    </th>
                    <th>Photo</th>
                    <th>Titre</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((product) => (
                    <tr key={product._id} product={product}>
                      <td>
                        <span className="custom-checkbox">
                          <input
                            type="checkbox"
                            id="checkbox1"
                            name="options[]"
                            value="1"
                          />
                          <label for="checkbox1" />
                        </span>
                      </td>

                      <td>
                        {" "}
                        <img
                          className="img-fluid w-70 img-card preview"
                          src={process.env.REACT_APP_DOMAIN +`/uploads/${product.fileNames[0]}`}
                          alt="product"
                        />
                      </td>
                      <td>{product.productName}</td>
                      <td>
                        <p>
                          {product.productDesc.length > 60
                            ? product.productDesc.substring(0, 60) + "..."
                            : product.productDesc.substring(0, 60)}
                        </p>
                      </td>
                      <td>
                        {/* <Link
                          to={`/admin/edit/product/${product._id}`}
                          data-target="#editEmployeeModal"
                          className="edit"
                          data-toggle="modal"
                        >
                          <i class="fas fa-pen" />
                        </Link> */}
                        <Link
                          data-target="#deleteEmployeeModal"
                          className="delete"
                          data-toggle="modal"
                          onClick={() => dispatch(deleteProduct(product._id))}
                        >
                          <i class="fas fa-trash" />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="clearfix">
                <div className="hint-text">
                  Showing <b>5</b> out of <b>25</b> entries
                </div>
                <ul className="pagination">
                  <li className="page-item disabled">
                    <Link to="#">Previous</Link>
                  </li>
                  <li className="page-item">
                    <Link to="#" className="page-link">
                      1
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link to="#" className="page-link">
                      2
                    </Link>
                  </li>
                  <li className="page-item active">
                    <Link to="#" className="page-link">
                      3
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link to="#" className="page-link">
                      4
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link to="#" className="page-link">
                      5
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link to="#" className="page-link">
                      Next
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <AdminCategoryModal />
        <AdminProductModal />
      </body>
    </div>
  );
}
