import React from 'react'

export  const showLoading=()=>{
    return (
        <div>
            <div className='spinner-grow text-secondary' role='status'>
                <span className='sr-only'>Loading...</span>
            </div>
            <div className='spinner-grow text-secondary' role='status'>
                <span className='sr-only'>Loading...</span>
            </div>
            <div className='spinner-grow text-secondary' role="status">
                <span className='sr-only'>Loading...</span>
            </div>
            
        </div>
    )
    }
