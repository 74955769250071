import axios from "axios";
import { START_LOADING, STOP_LOADING } from "../constants/loadingConstants";
import {
  SHOW_ERROR_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
} from "../constants/messageConstants";
import {
  CREATE_CAROUSEL,
  GET_CAROUSELS,
  GET_CAROUSEL,
  DELETE_CAROUSEL,
} from "../constants/carouselConstants";

export const createCarousel = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.post(
      process.env.REACT_APP_DOMAIN + "/api/carousel",
      formData, config

    );
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: SHOW_SUCCESS_MESSAGE,
      payload: response.data.successMessage,
    });
    dispatch({
      type: CREATE_CAROUSEL,
      payload: response.data.carousel,
    });
  } catch (err) {
    console.log("createCarousel api error: ", err);
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: SHOW_ERROR_MESSAGE,
      payload: err.response.data.errorMessage,
    });
  }
};

export const getCarousels = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.get(
      process.env.REACT_APP_DOMAIN + "/api/carousel", config
    );
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: GET_CAROUSELS,
      payload: response.data.carousels,
    });
  } catch (err) {
    console.log("getCarousels api error: ", err);
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: SHOW_ERROR_MESSAGE,
      payload: err.response.data.errorMessage,
    });
  }
};

export const getCarousel = (carouselId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.get(
      process.env.REACT_APP_DOMAIN + `/api/carousel/${carouselId}`, config
    );
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: GET_CAROUSEL,
      payload: response.data,
    });
  } catch (err) {
    console.log("getCarousels api error: ", err);
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: SHOW_ERROR_MESSAGE,
      payload: err.response.data.errorMessage,
    });
  }
};

export const deleteCarousel = (carouselId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.delete(
      process.env.REACT_APP_DOMAIN + `/api/carousel/${carouselId}`, config
    );
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: DELETE_CAROUSEL,
      payload: response.data,
    });
  } catch (err) {
    console.log("deleteCarousel api error: ", err);
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: SHOW_ERROR_MESSAGE,
      payload: err.response.data.errorMessage,
    });
  }
};
