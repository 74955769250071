import React from "react";
import "./search.scoped.css";


function Search({onChange}) {

  return (
    <div className="d-flex w-100 py-4 px-3 px-2 px-lg-5">
      <div className="row flex-nowrap w-100 py-2 px-2 mx-0 mx-md-1 align-items-center rounded bg-light justify-content-between">
        <input
        onChange={(e)=>{onChange(e.target.value)}}
          type="text"
          id="search"
          className="bg-light d-flex w-full flex-grow-1"
          placeholder="Rechercher véhicule, marque, année ..."
        />
        <span className="fa fa-search text-muted" />
      </div>
    </div>
  );
}

export default Search;
