import { Link } from "react-router-dom";
import "./productTable.scoped.css";
import "./Dashboard.css";
import AdminArticleModal from "./AdminArticleModal";
import { useDispatch } from "react-redux";
import { deleteArticle, getArticles } from "../redux/actions/articleActions";
import { useSelector } from "react-redux";

export default function ProductTable({ article }) {
  const { articles } = useSelector((state) => state.articles);
  const dispatch = useDispatch();

  function RemoveArticle(id) {
    dispatch(deleteArticle(id));
    dispatch(getArticles());
  }

  // useEffect(() => {
  //   dispatch(getArticles())
  // }, [])
  /***************************************************
   * RENDER VIEW
   ***************************************************/
  return (
    <div>
      <body>
        <div className="container-xl">
          <div className="table-responsive">
            <div className="table-wrapper">
              <div className="table-title">
                <div className="row">
                  <div class="col-sm-6">
                    <h2>
                    Gérer Les <b>Articles</b>
                    </h2>
                  </div>
                  <div className="col-sm-6">
                    <Link
                      data-target="#addArticleModal"
                      className="btn btn-secondary"
                      data-toggle="modal"
                    >
                      <i class="fas fa-plus-circle" />

                      <span>Ajouter Un Article</span>
                    </Link>
                  </div>
                </div>
              </div>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>
                      <span className="custom-checkbox">
                        <input type="checkbox" id="selectAll" />
                        <label for="selectAll" />
                      </span>
                    </th>
                    <th>Photo</th>
                    <th>Titre</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {articles?.map((article, index) => (
                    <tr key={article._id} article={article}>
                      <td>
                        <span className="custom-checkbox">
                          <input
                            type="checkbox"
                            id={`checkbox${index}`}
                            name={`checkbox${index}`}
                            value={article.name}
                          />
                          <label for={`checkbox${index}`} />
                        </span>
                      </td>

                      <td>
                        {" "}
                        <img
                          className="img-fluid w-100 img-card "
                          src={process.env.REACT_APP_DOMAIN +`/uploads/${article.fileName}`}
                          alt="product"
                        />
                      </td>
                      <td>
                        {" "}
                        <span className="text-secondary mr-2">
                          {article.articleName.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </span>
                      </td>

                      <td>
                        {/* <Link
                          to={`/admin/edit/article/${article._id}`}
                          data-target="#editEmployeeModal"
                          className="edit"
                          data-toggle="modal"
                        >
                          <i class="fas fa-pen" />
                        </Link> */}
                        <Link
                          className="delete"
                          onClick={() => {
                            RemoveArticle(article._id);
                          }}
                        >
                          <i class="fas fa-trash" />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="clearfix">
                <div className="hint-text">
                  Showing <b>5</b> out of <b>25</b> entries
                </div>
                <ul className="pagination">
                  <li className="page-item disabled">
                    <Link to="#">Previous</Link>
                  </li>
                  <li className="page-item">
                    <Link to="#" className="page-link">
                      1
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link to="#" className="page-link">
                      2
                    </Link>
                  </li>
                  <li className="page-item active">
                    <Link to="#" className="page-link">
                      3
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link to="#" className="page-link">
                      4
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link to="#" className="page-link">
                      5
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link to="#" className="page-link">
                      Next
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <AdminArticleModal />
      </body>
    </div>
  );
}
