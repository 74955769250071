import axios from "axios";

export const createCategory = async (formData) => {

  
const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
    withCredentials: true,
  };

  const response = await axios.post(
    process.env.REACT_APP_DOMAIN + "/api/category",
    formData,
    config
  );
  return response;
};

//applied in the categoryAction file (this function can now be deleted)
export const getCategories = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  const response = await axios.get(
    process.env.REACT_APP_DOMAIN + "/api/category",
    config
  );
  return response;
};
