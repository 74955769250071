import  { useState } from "react";
import { showErrorMsg, showSuccessMsg } from "./helpers/message";
import { showLoading } from "./helpers/Loading";
import isEmpty from "validator/lib/isEmpty";
import "./Dashboard.css";
//redux
import { useSelector, useDispatch } from "react-redux";
import { clearMessages } from "../redux/actions/messageActions";
import {
  createCategory,
} from "../redux/actions/categoryActions";



function AdminCategoryModal() {

  /***************************************************
 * REDUX GLOBAL STATE PROPERTIES
 ***************************************************/

const { successMsg} = useSelector((state) => state.messages);
const { loading } = useSelector((state) => state.loading);
const dispatch = useDispatch()

  /***************************************************
   * COMPONENTS STATE PROPERTIES
   ***************************************************/

  const [category, setCategory] = useState("");
  const [clientSideErrorMsg, setClientSideErrorMsg]= useState('')


  /***************************************************
   * EVENT HANDLERS
   ***************************************************/

  const handleMessages = (evt) => {
    dispatch(clearMessages());
  };

  const handleCategoryChange = (evt) => {
    dispatch(clearMessages());
    setCategory(evt.target.value);
  };
  const handleCategorySubmit = (evt) => {
    evt.preventDefault();

    if (isEmpty(category)) {
      setClientSideErrorMsg("Please enter a category");
    } else {
      const data = { category };

   
      dispatch(createCategory(data));
      setCategory('');
    }
  };
  /***************************************************
   * RENDER VIEW
   ***************************************************/
  return (
    <div id="addEmployeeModal" className="modal fade" onClick={handleMessages}>
    <div className="modal-dialog">
      <div className="modal-content">
        <form onSubmit={handleCategorySubmit}>
          <div className="modal-header">
            <h4 className="modal-title">Ajouter Une Catégorie</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body" >
          {clientSideErrorMsg && showErrorMsg(clientSideErrorMsg)}
        {successMsg && showSuccessMsg(successMsg)}
        {loading ? (
          <div className="text-center">{showLoading()}</div>
        ) : (
            <div>
            <div className="form-group">
              <label>Titre</label>
              <input type="text"   value={category} className="form-control" required onChange={handleCategoryChange} /> </div>
            
            </div>
              )}
          </div>
          <div className="modal-footer">
            <input
              type="button"
              className="btn btn-white"
              data-dismiss="modal"
              value="Annuler"
            />
            <input
              type="submit"
              className="btn btn-secondary"
              value="Ajouter"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
  );
}

export default AdminCategoryModal;


