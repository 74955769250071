import {
    GET_ARTICLES,
    GET_ARTICLE,
    CREATE_ARTICLE,
    DELETE_ARTICLE,
  } from "../constants/articleConstants";
  
  const INITIAL_STATE = {
    articles: [],
    article:{}
  };
  
  const articleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case CREATE_ARTICLE:
        return {
          articles: [...state.articles, action.payload],
        };
      case GET_ARTICLES:
        return {
          articles: [...action.payload],
        };
        case GET_ARTICLE:
          return {
            article: action.payload,
          };
      case DELETE_ARTICLE:
        return {
          articles: state.articles.filter(p => p._id !== action.payload._id),
        };
      default:
        return state;
    }
  };
  export default articleReducer;
  