import React, { useState, useRef, useEffect } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import "./navbar.scoped.css";
import { isAuthenticated, logout } from "./helpers/auth";
import logo from "../images/logo.png";
import HeadShake from "react-reveal/HeadShake";
import { HashLink } from "react-router-hash-link";
import useSound from "use-sound";
import boopSfx from "./boop.mp3";

export function Navbar({ history }) {
  
  const [play] = useSound(boopSfx);
  const location = useLocation();

  const [isMobile, SetMobileStatus] = useState(window.innerWidth < 965);
  

  window.addEventListener("resize", function () {
    SetMobileStatus(window.innerWidth < 965);
  });

  const handleLogout = (evt) => {
    logout(() => {
      history.push("/signup");
    });
  };
  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  useEffect(() => {
    console.log('running  ')
    if (location.pathname !== "/") {
      setNavBackground(true);
      return;
    }else{
      setNavBackground(false);
    }
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [location]);


  return (
    <div>
      <body>
        <nav
          className="navbar navbar-expand-lg navbar-light fixed-top"
          style={{
            transition: "1s ease",
            backgroundColor: navBackground ? "#eeeeee" : "transparent",
          }}
        >
          <div className="container">
            <HeadShake>
              <Link to="/" className="navbar-brand">
                <img
          
                  className="logo-img"
                  src={logo}
                  alt="motor technology solution"
                />
              </Link>
            </HeadShake>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <HeadShake>
                <ul className="navbar-nav ml-auto" style={{
              boxShadow: isMobile ? 'inset 5px 5px 5px rgba(0, 0, 0, 0.05)' : 'none',
              borderRadius:'10px',
              padding: isMobile ? '6px 10px 16px 10px' : '0px',
              backgroundColor: isMobile ? 'white' : 'transparent' }}>
                  {!isAuthenticated() && (
                    <>
                      <li className="nav-item active">
                      <HashLink
                          className="nav-link"
                          smooth
                          to="/#home"
                          style={{
                            color: "grey"
                          }}
                        >
                          Home
                        </HashLink>
                      </li>
                      <li className="nav-item">
                        <HashLink
                          className="nav-link"
                          smooth
                          to="/#produit"
                          style={{
                            color: "grey"
                          }}
                        >
                          VÉHICULES
                        </HashLink>
                      </li>
                      <li className="nav-item">
                        <HashLink
                          className="nav-link"
                          smooth
                          to="/#service"
                          style={{
                            color: "grey"
                          }}
                        >
                          services
                        </HashLink>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/about-us"
                          style={{
                            color: "grey"
                          }}
                        >
                          HISTOIRE
                        </Link>
                      </li>{" "}
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/blog"
                          style={{
                            color: "grey"
                          }}
                        >
                          News
                        </Link>
                      </li>{" "}
                      <li
                        className="nav-item"
                        style={{
                          color: "grey"
                        }}
                      >
                        <Link className="nav-link" to="/signup"
                        style={{
                          color: "grey"
                        }}>
                          CONNEXION
                        </Link>
                      </li>
                      <li className="nav-item">
                        <HashLink
                         onClick={play}
                          className="nav-link"
                          smooth
                          to="/#foot"
                          style={{
                            color: navBackground
                              ? "white"
                              : "white",
                               backgroundColor:'rgba(20, 92, 148, 1)',
                               borderRadius:'40px',
                               padding:'6px',
                               paddingRight:'25px',
                               paddingLeft:'25px',
                          }}
                        >
                          CONTACT
                        </HashLink>
                      </li>
                    </>
                  )}
                  {isAuthenticated() && isAuthenticated().role === 0 && (
                    <>
                      <li className="nav-item active">
                        <Link className="nav-link" to="/"  style={{
                          color: navBackground
                            ? "grey "
                            : "white",
                        }}>
                          Home
                        </Link>
                      </li>
                    </>
                  )}
                  {isAuthenticated() && isAuthenticated().role === 1 && (
                    <>
                    <li className="nav-item active">
                        <Link className="nav-link" to="/"  style={{
                          color: navBackground
                            ? "grey "
                            : "white",
                        }}>
                          Home
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link className="nav-link" to="admin/dashboard"  style={{
                          color: navBackground
                            ? "grey "
                            : "white",
                        }}>
                          dashboard
                        </Link>
                      </li>
                      
                    </>
                  )}
                  {isAuthenticated() && (
                    <>
                      <li className="nav-item active"  style={{
                          color: navBackground
                            ? "grey "
                            : "white",
                        }}>
                        <Link
                         style={{
                          color: navBackground
                            ? "grey "
                            : "white",
                        }}
                          className="nav-link"
                          to="/"
                          onClick={handleLogout}
                        >
                          Déconnexion
                        </Link>
                      </li>
                      
                    </>
                  )}
                </ul>
              </HeadShake>
            </div>
          </div>
        </nav>
      </body>
    </div>
  );
}
export default withRouter(Navbar);
