import "./about.scoped.css";
import React from "react";

export default function About() {
  return (
    <div>
      <body>
        <div class="about-section">
          <div class="inner-container">
            <h2 className="about-title font-weight-bold" To="#">
              <span className="burgundy">Notre</span>
              <span className="center-margin orange">Histoire</span>
            </h2>
            <p class="text">
              MTS, Motor Technology Solution, est née d’une passion pour la
              marque à l’hélice. C’est à l’occasion des années 70 que tout a
              commencé, finalement très peu de temps après la naissance de son
              fondateur qui, béat devant une superbe e9 3.0 csi Fjord blue puis
              une exceptionnelle e12 525i Polaris grau, a attrapé ce virus et
              décidé que son histoire automobile croiserait le plus souvent
              possible la sportivité, la fiabilité et l’élégance sur trains
              roulants de BMW, Motorsport, Alpina et Hartge. C’est cette passion
              qu’MTS cherche à transmettre à sa clientèle, en proposant des
              modèles très ciblés, des pièces rares, des accessoires OEM et des
              services associés tels que la réhabilitation ou la préparation
              concours et circuits des véhicules munichois. L’ensemble de nos
              activités et prestations s’inscrit dans l’histoire de la marque
              d’une part, ainsi que dans celles des différents préparateurs qui
              ont participé aux développements de BMW d’autre part. Nous aimons
              ce que nous faisons et aurons beaucoup de plaisir à vous
              accompagner dans la légende !
            </p>
            <div class="skills">
              <span>Fiabilité</span>
              <span>Service & Qualité</span>
              <span>Certifié</span>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}
