import React from "react";
import "./footer.scoped.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer" id="foot">
      <div className="row">
        <div className="col-sm-6 col-md-4 footer-navigation" id="ft">
          <h3>
            <Link to="#">
              Nous<span>Contacter</span>
            </Link>
          </h3>
          <p className="links">
            <Link to="/#produits">Produits</Link>
            <strong> · </strong>
            <Link to="/#services">Services</Link>
            <strong> · </strong>
            <Link to="#">News</Link>
            <strong> · </strong>
            <Link to="#">Histoire</Link>
          </p>
          <p className="company-name">MOTOR TECHNOLOGY SOLUTION © 2021</p>
        </div>
        <div className="col-sm-6 col-md-4 footer-contacts">
          <div>
            <span className="fa fa-map-marker footer-contacts-icon"> </span>
            <p>
              <span className="new-line-span"> Route du Verney 18</span>1070,
              Puidoux
            </p>
          </div>
          <div>
            <i className="fa fa-phone footer-contacts-icon" />
            <a
              href="tel:+41 32 435 15 81"
              className="footer-center-info email text-left"
            >
              +41 32 435 15 81
            </a>
          </div>
          <div>
            <i className="fa fa-envelope footer-contacts-icon" />
            <p>
              {" "}
              <a href="mailto: mari.j27@gmail.com" to="#">
                support@MTS.com
              </a>
            </p>
          </div>
        </div>
        <div className="clearfix" />
        <div className="col-md-4 footer-about">
          <h4 id="footi">Qui Sommes-Nous ?</h4>
          <p>
            MTS, Motor Technology Solution, est née d’une passion pour la marque
            à l’hélice. C’est à l’occasion des années 70 que tout a commencé,
            finalement très peu de temps après la naissance de son fondateur
            qui, béat devant uants de BMW, Motorsport, Alpina et Hartge
          </p>
          <div className="social-links social-icons">
            <Link>
              <i className="fab fa-facebook" />
            </Link>
            <a href="https://www.instagram.com/motortechnologysolution">
              <i className="fab fa-instagram" />
            </a>
            <Link to="#">
              <i className="fab fa-youtube" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
