import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./Home.js";
import NotFound from "./NotFound.js";
import Signin from "./Signin.js";
import Signup from "./Signup.js";
import AdminDashboard from "./AdminDashboard";
import "./App.css";
import AdminRoute from "./AdminRoute.js";
import About from "./About.js";
import Service from "./Service.js";
import AdminEditProduct from "./AdminEditProduct";
import Blogs from "./Blogs.js";
import Navbar from "./Navbar.js";
import ProductDetails from "./ProductDetails.js";

function App() {
  return (
    <BrowserRouter>
      {/* <Header /> */}

      <Navbar />

      <main className="website-body">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about-us" component={About} />
          <Route exact path="/services" component={Service} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/blog" component={Blogs} />
          <Route
            exact
            key={1}
            path="/product/:productId"
            component={ProductDetails}
          />
          <AdminRoute
            exact
            path="/admin/dashboard"
            component={AdminDashboard}
          />
          <AdminRoute
            exact
            path="/admin/edit/product/:productId"
            component={AdminEditProduct}
          />
          <Route component={NotFound} />
        </Switch>
      </main>
    </BrowserRouter>
  );
}

export default App;
