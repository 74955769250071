import React, { useState,useEffect } from "react";
import Search from "./Search";
import ProductCard from "./ProductCard";
import ListProduct from "./ListProduct";
import { searchProduct } from "./api/product";

export default function Products() {
 
  const [searchkeyword, setsearchkeyword] = useState('')
  const [searchedProducts, setsearchedProducts] = useState('')
  const [productBrand, setproductBrand] = useState('')
  const [category, setcategory] = useState('')
  const [year, setyear] = useState('')
  useEffect(() => {
  

      search()
   
  }, [searchkeyword,productBrand,year,category])
  const search = async () => {

    let res = await searchProduct({ keyword: searchkeyword,productBrand,year,category })
    console.log(res)
    if(!res.err){
      setsearchedProducts(res.data.results)
    }
  }
  return (<div
    id="row-container"
    className="row"
    style={{ marginRight: 0, marginLeft: 0 }}
  >
    <div className="col-12 col-md-5 col-lg-4">
      <ListProduct category={category} setcategory={setcategory} setYear={setyear} year={year} setbrandName={setproductBrand}/>
    </div>
    <div className="col-12 col-md-7 col-lg-8">
      <div>
        <Search onChange={setsearchkeyword} />
        <div id="products" className="row justify-content-around">
          {searchedProducts&&searchedProducts?.map((product) => (
            <div className="mx-1">
              <ProductCard key={product._id} product={product} />
            </div>
          ))}
        </div>
      </div>

    </div>
  </div>
  );
}
