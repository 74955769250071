import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../redux/actions/productActions";
import { Link } from "react-router-dom";
import axios from "axios";

export default function AdminEditProduct({ match, history }) {
  /********************************
   * PARAMS
   *******************************/
  const productId = match.params.productId;

  /********************************
   * REDUX GLOBAL STATE PROPERTIES
   *******************************/
  const dispatch = useDispatch();
  const { product } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.categories);

  /********************************
   * COMPONENTS STATE PROPERTIES
   *******************************/
  const [productImage, setProductImage] = useState(null);
  const [productVideo, setProductVideo] = useState("");
  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productQty, setProductQty] = useState("");
  const [productStatus, setProductStatus] = useState("");

  /********************************
   * LIFECYLE METHODS
   *******************************/

  useEffect(() => {
    if (!product) {
      dispatch(getProduct(productId));
    } else {
      setProductImage(product.fileName);
      setProductVideo(product.productVideo);
      setProductName(product.setProductName);
      setProductDesc(product.productDesc);
      setProductCategory(product.productCategory);
      setProductBrand(product.productBrand);
      setProductQty(product.productQty);
      setProductStatus(product.productStatus);
    }
  }, [dispatch, productId, product]);

  /********************************
   * EVENT HANDLERS
   *******************************/

  const handleImageUpload = (e) => {
    const image = e.target.files[0];
    setProductImage(image);
  };

  const handleProductSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("productVideo", productVideo);
    formData.append("productName", productName);
    formData.append("productDesc", productDesc);
    formData.append("productCategory", productCategory);
    formData.append("productBrand", productBrand);
    formData.append("productQty", productQty);
    formData.append("productStatus", productStatus);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    await axios
      .put(`/api/product/${productId}`, formData, config)
      .then((res) => {
        history.push("/admin/dashboard");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /********************************
   * RENDERING
   *******************************/

  return (
    <div>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <Link to="/admin/dashboard">
              <span className="fas fa-arrow-left">Retour</span>
            </Link>
            <div>
              <br />
              <div className="modal-content">
                <form onSubmit={handleProductSubmit}>
                  <div className="modal-header bg-white text-secondary border-none">
                    <h5 className="modal-title">Modifier un produit</h5>
                  </div>
                  <div className="modal-body my-2">
                    <>
                      <label className="btn btn-secondary mr-4">
                        Choisir une image(s)
                        <input
                          type="file"
                          name="productImage"
                          accept="images/*"
                          hidden
                          multiple
                          value={productImage}
                          onChange={handleImageUpload}
                        />
                      </label>
                      {productImage && productImage.name ? (
                        <span className="badge badge-secondary">
                          {productImage.name}
                        </span>
                      ) : productImage ? (
                        <img
                          className="img-thumbnail"
                          style={{
                            width: "120px",
                            height: "80px",
                          }}
                          src={`/uploads/${productImage}`}
                          alt="product"
                        />
                      ) : null}

                      <div className="form-group">
                        <label className="text-secondary">Video</label>
                        <input
                          type="text"
                          className="form-control"
                          name="productVideo"
                          value={productVideo}
                          onChange={(e) => setProductVideo(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label className="text-secondary">Titre</label>
                        <input
                          type="text"
                          className="form-control"
                          name="productName"
                          value={productName}
                          onChange={(e) => setProductName(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label className="text-secondary">Description</label>
                        <textarea
                          className="form-control"
                          rows="3"
                          name="productDesc"
                          value={productDesc}
                          onChange={(e) => setProductDesc(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="text-secondary">Marque</label>
                        <input
                          type="text"
                          className="form-control"
                          name="productBrand"
                          value={productBrand}
                          onChange={(e) => setProductBrand(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="text-secondary">Année</label>
                        <input
                          type="number"
                          name="productQty"
                          value={productQty}
                          className="form-control"
                        />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="text-secondary">Catégories</label>
                          <select
                            className="custom-select mr-sm-2"
                            name="productCategory"
                            value={productCategory}
                            onChange={(e) => setProductCategory(e.target.value)}
                          >
                            <option value="">Selectionner...</option>
                            {categories &&
                              categories.map((c) => (
                                <option key={c._id} value={c._id}>
                                  {c.category}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="form-group">
                          <label className="text-secondary">
                            Status (Vendu ou Disponible)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="productStatus"
                            value={productStatus}
                            onChange={(e) => setProductStatus(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-secondary text-white"
                    >
                      Sauvegarder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
