import { useState } from "react";
import { showErrorMsg, showSuccessMsg } from "./helpers/message";
import { showLoading } from "./helpers/Loading";
import "./Dashboard.css";
import "./productTable.scoped.css";
import { Link } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
import { clearMessages } from "../redux/actions/messageActions";
import { createCarousel } from "../redux/actions/carouselAction";
import { deleteCarousel } from "../redux/actions/carouselAction";
import { getCarousels } from "../redux/actions/carouselAction";

function AdminCarouselModal({ carousel }) {
  /***************************************************
   * REDUX GLOBAL STATE PROPERTIES
   ***************************************************/
  const { carousels } = useSelector((state) => state.carousels);
  const { loading } = useSelector((state) => state.loading);
  const { successMsg, errorMsg } = useSelector((state) => state.messages);
  const dispatch = useDispatch();

  // function RemoveCarousel(id) {
  //   dispatch(deleteCarousel(id));
  //   dispatch(getCarousels());
  // }

  /***************************************************
   * COMPONENT STATE PROPERTIES
   ***************************************************/
  const [clientSideError, setClientSideError] = useState("");
  const [carouselData, setCarouselData] = useState({
    carouselImage: null,
    carouselName: "",
  });

  const { carouselImage, carouselName } = carouselData;

  const [previewImages, setPreviewImages] = useState([]);

  /***************************************************
   * EVENT HANDLERS
   ***************************************************/
  const handleMessages = (evt) => {
    dispatch(clearMessages());
    setClientSideError("");
  };

  const handleCarouselImage = (evt) => {
    console.log(evt.target.files);
    const imageFiles = [];
    Array.from(evt.target.files).forEach((image) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        imageFiles.push(e.target.result);
        setPreviewImages(imageFiles);
      };
    });
    setCarouselData({
      ...carouselData,
      [evt.target.name]: evt.target.files,
    });
  };

  const handleCarouselChange = (evt) => {
    setCarouselData({
      ...carouselData,
      [evt.target.name]: evt.target.value,
    });
  };
  const handleCarouselSubmit = (evt) => {
    evt.preventDefault();
    if (carouselImage === null) {
      setClientSideError("please select an image");
    } else {
      let formData = new FormData();
      let images = Array.from(carouselImage);
      images.forEach((image) => {
        formData.append("carouselImage", image);
      });
      formData.append("carouselName", carouselName);

      dispatch(createCarousel(formData));
      setCarouselData({
        carouselImage: null,
        carouselName: "",
      });
    }
  };

  return (
    <div>
      {/* <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>
              <span className="custom-checkbox">
                <input type="checkbox" id="selectAll" />
                <label for="selectAll" />
              </span>
            </th>
            <th>Photo</th>
            <th>Titre</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {carousels?.map((carousel, index) => (
            <tr key={carousel._id} article={carousel}>
              <td>
                <span className="custom-checkbox">
                  <input
                    type="checkbox"
                    id={`checkbox${index}`}
                    name={`checkbox${index}`}
                    value={carousel.name}
                  />
                  <label for={`checkbox${index}`} />
                </span>
              </td>

              <td>
                {" "}
                <img
                  className="img-fluid w-100 img-card "
                  src={
                    process.env.REACT_APP_DOMAIN +
                    `/uploads/${carousel.fileNames[0]}`
                  }
                  alt="product"
                />
              </td>
              <td>
                {" "}
                <span className="text-secondary mr-2">
                  {carousel.carouselName.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </td>

              <td>
                <Link
                  className="delete"
                  onClick={() => {
                    RemoveCarousel(carousel._id);
                  }}
                >
                  <i class="fas fa-trash" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}

      <div
        id="editCarouselModal"
        className="modal fade"
        onClick={handleMessages}
      />
      <div className="modal-dialog">
        <div className="modal-content">
          <form onSubmit={handleCarouselSubmit} enctype="multipart/form-data">
            <div className="modal-header">
              <h4 className="modal-title">Carousel Images</h4>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {clientSideError && showErrorMsg(clientSideError)}
              {errorMsg && showErrorMsg(errorMsg)}
              {successMsg && showSuccessMsg(successMsg)}
              {loading ? (
                <div className="text-center">{showLoading()}</div>
              ) : (
                <>
                  <label>Images</label>
                  <div className="form-group custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      multiple
                      name="carouselImage"
                      onChange={handleCarouselImage}
                    />

                    <label className="custom-file-label">
                      Choisir des images
                    </label>
                  </div>
                  {previewImages.map((image, index) => (
                    <img
                      className="preview"
                      key={"image" + index}
                      src={image}
                      alt="mts"
                    />
                  ))}

                  <div className="form-group">
                    <label>Titre</label>
                    <input
                      className="form-control"
                      rows="3"
                      required
                      name="carouselName"
                      onChange={handleCarouselChange}
                    />
                  </div>
                  <div className="modal-footer">
              <input
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                value="Annuler"
              />

              <input
                type="submit"
                className="btn btn-secondary"
                value="Enregistrer"
              />
            </div>
                </>
              )}
            </div>

           
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminCarouselModal;
