import React from "react";
import { Link } from "react-router-dom";
import "./featured.scoped.css";
import { HashLink } from "react-router-hash-link";
export default function Featured() {
  return (
    <div className="w-100" >
      <Link
        className="m d-flex justify-content-center align-items-center font-weight-bold py-1 text-dark align-middle"
        To="#"
      >
        <p className="burgundy">Nos</p>
        <p className="orange pt-1">Catégories</p>
      </Link>
      <body>
        <div className="card">
          <img
            alt="bmw"
            src="https://images.pexels.com/photos/2127024/pexels-photo-2127024.jpeg?cs=srgb&dl=pexels-maria-geller-2127024.jpg&fm=jpg"
          />
          <div className="card-content">
            <HashLink smooth to="/#produit">
              <p className="card-category">
                Véhicules
                <i className="fas fa-chevron-right fa-sm" />
              </p>
            </HashLink>
          </div>
        </div>

        <div className="card">
          <img
            alt="bmw"
            src="https://images.pexels.com/photos/3822938/pexels-photo-3822938.jpeg?cs=srgb&dl=pexels-andrea-piacquadio-3822938.jpg&fm=jpg"
          />
          <div className="card-content">
            <HashLink smooth to="/#produit">
              <p className="card-category">
                Pièces
                <i className="fas fa-chevron-right fa-sm" />
              </p>
            </HashLink>
          </div>
        </div>
        <div className="card">
          <img
            alt="bmw"
            src="https://images.pexels.com/photos/1729991/pexels-photo-1729991.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          />
          <div className="card-content">
            <HashLink smooth to="/#produit">
              <p className="card-category">
                Collector
                <i className="fas fa-chevron-right fa-sm" />
              </p>
            </HashLink>
          </div>
        </div>
      </body>
    </div>
  );
}
