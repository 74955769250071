import React from "react";
import { Link } from "react-router-dom";
import "./search.scoped.css";

export default function ProductCard({ product }) {

  return (
    <div
      id="products"
      className="product-item card row justify-content-around mx-1"
    >
      <img
        className="card-img-top"
        src={process.env.REACT_APP_DOMAIN + `/uploads/${product.thumbnail}`}
        alt="product"
      />
      <div className="card-body mt-3">
        <h5>
          <b>{product.productName}</b>
        </h5>
        <div className="d-flex flex-row my-2">
          <div className="text-muted">
            {" "}
            {product.productBrand.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </div>

          <div className="w-100" />
        </div>
        <Link
          to={`/product/${product._id}`}
          data-target="#productDetails"
          className="btn w-100 rounded my-2"
        >
          + d'info
        </Link>
      </div>
    </div>
  );
}
