import axios from "axios";
import { START_LOADING, STOP_LOADING } from "../constants/loadingConstants";
import {
  SHOW_ERROR_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
} from "../constants/messageConstants";
import {
  CREATE_ARTICLE,
  GET_ARTICLES,
  GET_ARTICLE,
  DELETE_ARTICLE,
} from "../constants/articleConstants";

export const createArticle = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.post(
      process.env.REACT_APP_DOMAIN + "/api/article",
      formData,
      config
    );
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: SHOW_SUCCESS_MESSAGE,
      payload: response.data.successMessage,
    });
    dispatch({
      type: CREATE_ARTICLE,
      payload: response.data.article,
    });
  } catch (err) {
    console.log("createArticle api error: ", err);
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: SHOW_ERROR_MESSAGE,
      payload: err.response.data.errorMessage,
    });
  }
};

export const getArticles = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.get(
      process.env.REACT_APP_DOMAIN + "/api/article",config
    );
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: GET_ARTICLES,
      payload: response.data.articles,
    });
  } catch (err) {
    console.log("getArticles api error: ", err);
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: SHOW_ERROR_MESSAGE,
      payload: err.response.data.errorMessage,
    });
  }
};

export const getArticle = (articleId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.get(
      process.env.REACT_APP_DOMAIN + `/api/article/${articleId}`, config
    );
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: GET_ARTICLE,
      payload: response.data,
    });
  } catch (err) {
    console.log("getArticles api error: ", err);
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: SHOW_ERROR_MESSAGE,
      payload: err.response.data.errorMessage,
    });
  }
};

export const deleteArticle = (articleId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.delete(
      process.env.REACT_APP_DOMAIN + `/api/article/${articleId}`, config
    );
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: DELETE_ARTICLE,
      payload: response.data,
    });
  } catch (err) {
    console.log("deleteArticle api error: ", err);
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: SHOW_ERROR_MESSAGE,
      payload: err.response.data.errorMessage,
    });
  }
};
