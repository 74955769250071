import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './App.css';
import { showErrorMsg } from './helpers/message';
import { showLoading } from './helpers/Loading';
import useSound from "use-sound";
import boopSfx from "./boop.mp3";
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import { signup } from './api/auth';
import { setAuthentication, isAuthenticated } from './helpers/auth';
import './authentication.scoped.css'


export default function Signup() {
    const [play] = useSound(boopSfx);
    let history = useHistory();
    useEffect(() => {
        if (isAuthenticated() && isAuthenticated().role === 1) {
            history.push('/admin/dashboard');
        } else if (isAuthenticated() && isAuthenticated().role === 0) {
            history.push('/');
        }

    }, [history])

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        errorMsg: false,
        loading: false,
        redirectToDashboard: false,
    });

    //data destructuration

    const { email, password, errorMsg, loading} = formData;

    //Event handlers
    const handleChange = evt => {

        setFormData({
            ...formData,
            [evt.target.name]: evt.target.value,
            errorMsg: ''
        });

    };
    const handleSubmit = evt => {
        evt.preventDefault();


        if (isEmpty(email) || isEmpty(password)) {
            setFormData({
                ...formData, errorMsg: "All fields are required"
            })

        } else if (!isEmail(email)) {
            setFormData({
                ...formData, errorMsg: "Invalid Email"
            })
        } else {
            const { email, password } = formData;
            const data = { email, password };

            setFormData({ ...formData, loading: true })

            signup(data)
                .then(response => {
                    setAuthentication(response.data.token, response.data.user);

                    if (isAuthenticated() && isAuthenticated().role === 1) {
                        console.log('redirect to admin dashboard')
                        history.push('/admin/dashboard');
                    } else {
                        console.log('redirect to homepage');
                        history.push('/');
                    }

                })
                .catch(err => {
                    console.log('signin api function error', err)
                    setFormData({
                        ...formData,
                        loading: false, 
                        errorMsg: err.response.data.errorMessage,
                    })
                });

        }


    };
    return (

    <html>
      <body>
        <div className="center-content">
          <div className="form-container">
          {errorMsg && showErrorMsg(errorMsg)}
                    {loading && <div className='text-center pb-4'>{showLoading()}</div>}
            <form onSubmit={handleSubmit}>
              <input onChange={handleChange} value={email}  name='email' type="email" placeholder="Email" required />
              <input onChange={handleChange} value={password} name='password' type="password" placeholder="Mot de passe" required />
              <button onClick={play} type="submit" className="button">
                Se connecter
              </button>
            </form>
          </div>
        </div>
      </body>
    </html>
    )
}


