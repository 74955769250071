import axios from "axios";

console.log("REACT_APP_DOMAIN: " + process.env.REACT_APP_DOMAIN);

export const createProduct = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  const response = await axios.post(
    process.env.REACT_APP_DOMAIN + "/api/product",
    data,
    config
  );

  return response;
};

export const getProducts = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  const response = await axios.get(
    process.env.REACT_APP_DOMAIN + "/api/product",
    config
  );
  return response;
};

export const searchProduct = async ({
  keyword,
  productBrand,
  year,
  category,
}) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : '*'
    },
    withCredentials: true,
  };
  const response = await axios
    .get(
      process.env.REACT_APP_DOMAIN +
        `/api/product/search?keyword=${keyword}&productBrand=${productBrand}&year=${year}&categoryID=${category}`,
      config
    )
    .catch(() => {
      return { err: true, message: "something went Wrong " };
    });
  return response;
};
