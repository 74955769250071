import { useState } from "react";
import { createArticle } from "./api/article";
import { getArticles } from "../redux/actions/articleActions";
import isEmpty from "validator/lib/isEmpty";
import { showErrorMsg, showSuccessMsg } from "./helpers/message";
import { showLoading } from "./helpers/Loading";
import { useDispatch } from "react-redux";
import "./Dashboard.css";

function AdminArticleModal() {
  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [articleData, setArticleData] = useState({
    articleImage: null,
    articleName: "",
    articleDesc: "",
    articleDate:"",
    articleLink:""
  
  });

  const {
    articleImage,
    articleDesc,
    articleName,
    articleDate, 
    articleLink
  
  } = articleData;



  
  /***************************************************
   * EVENT HANDLERS
   ***************************************************/

  const handleMessages = (evt) => {
    setErrorMsg("");
    setSuccessMsg("");
  };

  const handleArticleImage = (evt) => {
    console.log(evt.target.files[0]);
    setArticleData({
      ...articleData,
      [evt.target.name]: evt.target.files[0],
    });
  };

  const handleArticleChange = (evt) => {
    setArticleData({
      ...articleData,
      [evt.target.name]: evt.target.value,
    });
  };
  const handleArticleSubmit = (evt) => {
    evt.preventDefault();
    if (articleImage === null) {
      setErrorMsg("please select an image");
    } else if (
      isEmpty(articleName) ||
      isEmpty(articleDesc) ||
      isEmpty(articleDate) ||
      isEmpty(articleLink) 
      
    ) {
      setErrorMsg("All fields are required");
    } else {
      let formData = new FormData();
      formData.append("articleImage", articleImage);
      formData.append("articleName", articleName);
      formData.append("articleDesc", articleDesc);
      formData.append("articleDate", articleDate);
      formData.append("articleLink", articleLink);
     

      setLoading(true);
      createArticle(formData)
        .then((response) => {
          setLoading(false);
          setArticleData({
            articleImage: null,
            articleName: "",
            articleDesc: "",
            articleDate:"",
            articleLink:"",
           
          });
          dispatch(getArticles());
          setSuccessMsg(response.data.successMessage);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setErrorMsg(err.response.data.errorMessage);
        });
    }
  };

  return (
    <div id="addArticleModal" className="modal" onClick={handleMessages}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <form onSubmit={handleArticleSubmit}>
            <div className="modal-header bg-white text-secondary">
              <h5 className="modal-title">Add Article</h5>
              <button className="close" data-dismiss="modal">
                <span>
                  <i className="fas fa-times"></i>
                </span>
              </button>
            </div>

            <div className="modal-body my-2">
              {errorMsg && showErrorMsg(errorMsg)}
              {successMsg && showSuccessMsg(successMsg)}
              {loading ? (
                <div className="text-center">{showLoading()}</div>
              ) : (
                <>
                  <div className="custom-file mb-2 ">
                    <input
                      type="file"
                      className="custom-file-input"
                      multiple
                      name="articleImage"
                      onChange={handleArticleImage}
                    />
                    <label className="custom-file-label">Choisir une image</label>
                  </div>
                  <div className="form-group">
                    <label className="text-secondary">Titre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="articleName"
                      value={articleName}
                      onChange={handleArticleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="text-secondary">Date (11/11/1111)</label>
                    <input
                      type="text"
                      className="form-control"
                      name="articleDate"
                      value={articleDate}
                      onChange={handleArticleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="text-secondary">Description</label>
                    <textarea
                      className="form-control"
                      rows="3"
                      name="articleDesc"
                      value={articleDesc}
                      onChange={handleArticleChange}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label className="text-secondary">Lien</label>
                    <input
                      type="text"
                      className="form-control"
                      name="articleLink"
                      value={articleLink}
                      onChange={handleArticleChange}
                    />
                  </div>
              
                 
                </>
              )}
            </div>

            <div className="modal-footer">
              <button data-dismiss="modal" className="btn btn-white">
                Annuler
              </button>
              <button className="btn btn-secondary" type="submit">
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminArticleModal;
