import React from "react";
import "./blogPage.scoped.css";
//redux

export default function BlogPage({ article }) {
  return (
    <>
      <div class="container mt-5">
        <div class="row">
          <div class="col-12">
            <article class="blog-card">
              <div class="blog-card__background">
                <div class="card__background--wrapper">
                  <div>
                    <img
                      className="card-img-top card__background--main"
                      src={process.env.REACT_APP_DOMAIN +`/uploads/${article.fileName}`}
                      alt="article"
                    />

                    <div className="card__background--layer" />
                  </div>
                </div>
              </div>
              <div className="blog-card__head">
                <span className="date__box">
                  <span className="date__day">{article.articleDate}</span>
                </span>
              </div>
              <div className="blog-card__info">
                <h5>{article.articleName} </h5>

                <p>
                  {article.articleDesc.length > 12222
                    ? article.articleDesc.substring(0, 12222) + "..."
                    : article.articleDesc.substring(0, 12222)}
                </p>
                <a href={article.articleLink} className="btn btn--with-icon">
                  <i className="btn-icon fa fa-long-arrow-right" />
                  Lire plus ...
                </a>
              </div>
            </article>
          </div>
        </div>
      </div>

      <section className="detail-page">
        <div className="container mt-5" />
      </section>
    </>
  );
}
