import { useState } from "react";
import isEmpty from "validator/lib/isEmpty";
import { showErrorMsg, showSuccessMsg } from "./helpers/message";
import { showLoading } from "./helpers/Loading";
import "./Dashboard.css";
//redux
import { useDispatch, useSelector } from "react-redux";
import { clearMessages } from "../redux/actions/messageActions";
import { createProduct } from "../redux/actions/productActions";
import star from '../images/star.png'
function AdminProductModal() {
  /***************************************************
   * REDUX GLOBAL STATE PROPERTIES
   ***************************************************/

  const { loading } = useSelector((state) => state.loading);
  const { successMsg, errorMsg } = useSelector((state) => state.messages);
  const { categories } = useSelector((state) => state.categories);
  const dispatch = useDispatch();

  /***************************************************
   * COMPONENT STATE PROPERTIES
   ***************************************************/
  const [clientSideError, setClientSideError] = useState("");
  const [productData, setProductData] = useState({
    productImage: null,
    productVideo: null,
    productName: "",
    productDesc: "",
    productCategory: "",
    productBrand: "",
    productQty: "",
    productStatus: "",
  });

  const {
    productImage,
    productVideo,
    productDesc,
    productName,
    productQty,
    productBrand,
    productCategory,
    productStatus,
  } = productData;
  const [thumbnail, setthumbnail] = useState('')
  const [previewImages, setPreviewImages] = useState([]);

  /***************************************************
   * EVENT HANDLERS
   ***************************************************/
  const handleMessages = (evt) => {
    dispatch(clearMessages());
    setClientSideError("");
  };

  const handleProductImage = (evt) => {
    console.log(evt.target.files);

    const imageFiles = [];
    Array.from(evt.target.files).forEach((image) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        imageFiles.push(e.target.result);
        // setPreviewImages(imageFiles);
      };
    });
    setthumbnail(evt.target.files[0]?.name)
    setPreviewImages(evt.target.files);
    setProductData({
      ...productData,
      [evt.target.name]: evt.target.files,
    });
  };
  console.log(productData)
  const handleProductChange = (evt) => {
    setProductData({
      ...productData,
      [evt.target.name]: evt.target.value,
    });
  };
  const handleProductSubmit = (evt) => {
    evt.preventDefault();
    if (productImage === null) {
      setClientSideError("please select an image");
    } else if (
      isEmpty(productName) ||
      isEmpty(productVideo) ||
      isEmpty(productDesc) ||
      isEmpty(productCategory) ||
      isEmpty(productBrand) ||
      isEmpty(productQty) ||
      isEmpty(productStatus)
    ) {
      setClientSideError("All fields are required");
    } else {
      let formData = new FormData();
      let images = Array.from(productImage);
      

      images.forEach((image) => {
        formData.append("productImage", image);
      });
      formData.append("productName", productName);
      formData.append("productDesc", productDesc);
      formData.append("productVideo", productVideo);
      formData.append("productCategory", productCategory);
      formData.append("productBrand", productBrand);
      formData.append("productQty", productQty);
      formData.append("productStatus", productStatus);
      formData.append("thumbnail", thumbnail)

      // files.findIndex((file)=>{
      // return file.slice(0,thumbnail.length-8)==thumbnial 
      // })
      //       thumbnail.slice(0,thumbnail.length-8)

      dispatch(createProduct(formData));
      // setProductData({
      //   productImage: null,
      //   productName: "",
      //   productVideo: "",
      //   productDesc: "",
      //   productBrand: "",
      //   productCategory: "",
      //   productQty: "",
      //   productStatus: "",
      // });
    }
  };

  return (
    <div id="editEmployeeModal" className="modal fade" onClick={handleMessages}>
      <div className="modal-dialog">
        <div className="modal-content">
          <form onSubmit={handleProductSubmit} enctype="multipart/form-data">
            <div className="modal-header">
              <h4 className="modal-title">Ajouter Un Produit</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {clientSideError && showErrorMsg(clientSideError)}
              {errorMsg && showErrorMsg(errorMsg)}
              {successMsg && showSuccessMsg(successMsg)}
              {loading ? (
                <div className="text-center">{showLoading()}</div>
              ) : (
                <>
                  <div className="form-group custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      multiple
                      name="productImage"
                      onChange={handleProductImage}
                    />
                    <label className="custom-file-label">
                      Selectionner des images
                    </label>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                    {Array.from(previewImages).map((image, index) => {

                    
                      return (
                        <div onClick={() => { setthumbnail(image.name) }} style={{ width: "100px", height: '100px', borderRadius: "10px", margin: '10px' }}>
                          {
                            thumbnail == image.name &&
                            <img src={star} style={{ width: '20px', height: '20px', position: 'absolute', marginLeft: 10, marginTop: 10 }} />
                          }


                          <img
                            style={{ width: "100px", height: '100px', borderRadius: "10px", margin: '10px' }}
                            className="preview"
                            key={"image" + index}
                            src={URL.createObjectURL(image)}
                            alt="mts"
                          />
                        </div>
                      )
                    })}
                  </div>
                  <div className="form-group">
                    <label>Video</label>
                    <input
                      type="text"
                      className="form-control"
                      name="productVideo"
                      value={productVideo}
                      onChange={handleProductChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Titre</label>
                    <input
                      className="form-control"
                      rows="3"
                      required
                      name="productName"
                      value={productName}
                      onChange={handleProductChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      name="productDesc"
                      value={productDesc}
                      onChange={handleProductChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Marque</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      name="productBrand"
                      value={productBrand}
                      onChange={handleProductChange}
                    />
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label>Catégorie</label>
                      <select
                        className="form-control"
                        name="productCategory"
                        onChange={handleProductChange}
                      >
                        <option value="">Selectionner ...</option>
                        {categories &&
                          categories.map((c) => (
                            <option key={c._id} value={c._id}>
                              {c.category}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Année</label>
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        max="10000"
                        name="productQty"
                        value={productQty}
                        onChange={handleProductChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                <label>Statut (Vendu ou Disponible)</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  name="productStatus"
                  value={productStatus}
                  onChange={handleProductChange}
                />
              </div>
                </>
              )}
              
            </div>

            <div className="modal-footer">
              <input
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                value="Annuler"
              />
              <input type="submit" className="btn btn-secondary" value="Ajouter" />
            </div>
            
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminProductModal;
