import "./productDetail.scoped.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../redux/actions/productActions";
import ReactPlayer from "react-player";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { HashLink } from "react-router-hash-link";

export default function ProductPage({ match }) {
  /********************************
   * PARAMS
   *******************************/
  const productId = match.params.productId;

  /********************************
   * REDUX GLOBAL STATE PROPERTIES
   *******************************/
  const dispatch = useDispatch();
  const { product } = useSelector((state) => state.products);

  /********************************
   * COMPONENTS STATE PROPERTIES
   *******************************/
  const [productImages, setProductImages] = useState(null);
  const [productVideo, setProductVideo] = useState("");
  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [, setProductCategory] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productQty, setProductQty] = useState("");
  const [productStatus, setProductStatus] = useState("");
  /********************************
   * LIFECYLE METHODS
   *******************************/
  console.log("data");
  useEffect(() => {
    console.log("useeffect is working ", productId);
    if (!product) {
      dispatch(getProduct(productId));
    } else {
      console.log("working ", product);
      setProductImages(product.fileNames);
      setProductVideo(product.productVideo);
      setProductName(product.productName);
      setProductDesc(product.productDesc);
      setProductCategory(product.productCategory);
      setProductBrand(product.productBrand);
      setProductQty(product.productQty);
      setProductStatus(product.productStatus);
    }
  }, [dispatch, productId, product]);
  /********************************
   * RENDERER
   *******************************/
  return (
    <div>
      <body>
        <div class="container">
          <div class="card">
            <div class="container-fliud">
              <div class="wrapper row">
                <div class="col-md-6">
                  <div class="preview-pic tab-content">
                    {productImages
                      ? productImages.map((imageFile, index) => (
                          <div
                            className={
                              "tab-pane selected-image-area" +
                              (index === 0 ? " active" : "")
                            }
                            id={`pic-${index}`}
                          >
                            <Zoom>
                              <img
                                src={
                                  process.env.REACT_APP_DOMAIN +
                                  `/uploads/${imageFile}`
                                }
                                alt="IMG"
                              />
                            </Zoom>
                          </div>
                        ))
                      : null}
                  </div>
                  <ul class="preview-thumbnail nav nav-tabs">
                    {productImages
                      ? productImages.map((imageFile, index) => (
                          <li className={index === 0 ? "active" : ""}>
                            <div
                              className="tab-button"
                              data-target={`#pic-${index}`}
                              data-toggle="tab"
                            >
                              <img
                                src={
                                  process.env.REACT_APP_DOMAIN +
                                  `/uploads/${imageFile}`
                                }
                                alt="IMG"
                              />
                            </div>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
                <div class="details col-md-6">
                  <h3 class="product-title">{productName}</h3>
                  <h4 class="price">
                    <span>{productBrand}</span>
                  </h4>
                  <h6 class="product-status">
                    <i class="fas fa-circle" /> {productStatus}
                  </h6>
                  <p class="product-description">{productDesc}</p>

                  <h5 class="sizes">
                    Année:
                    <span class="size" data-toggle="tooltip" title="small">
                      {productQty}
                    </span>
                  </h5>

                  <div class="action">
                    <HashLink
                      smooth
                      to="/#foot"
                      class="add-to-cart btn btn-default"
                      type="button"
                    >
                      Nous contacter
                    </HashLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="player-wrapper">
              <ReactPlayer
                url={productVideo}
                className="react-player mt-4"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}
