import { useEffect } from "react";
import Products from "./Products";
import Service from "./Service";
import Featured from "./Featured.js";
import Footer from "./Footer.js";
import { Link } from "react-router-dom";
import "./featured.scoped.css";
import "./search.scoped.css";

//redux
import { useDispatch } from "react-redux";
import { getCategories } from "../redux/actions/categoryActions";
import { getProducts } from "../redux/actions/productActions";
import { getArticles } from "../redux/actions/articleActions";
import { getCarousels } from "../redux/actions/carouselAction";
import Carousel from "./Carousel";

export default function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getArticles());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getCarousels());
  }, [dispatch]);

  return (
    <>
      <div className="d-flex flex-column">
        <Carousel />
        <Featured />
        <Link
          id="produit"
          className="m d-flex justify-content-center align-items-center font-weight-bold py-1 text-dark align-middle"
          To="#"
        >
          <p className="burgundy" id="produit">
            Nos
          </p>
          <p className="orange pt-1">Produits</p>
        </Link>
        <div className="mx-auto product-container">
          <Products />
        </div>
        <Service />
      </div>
      <Footer />
    </>
  );
}
