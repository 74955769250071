import React from "react";
import useSound from "use-sound";
import boopSfx from "./boop.mp3";
import "./navbar.scoped.css";
import Bounce from "react-reveal/Bounce";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";

export default function Carousel() {
  const [play] = useSound(boopSfx);
  const { carousels } = useSelector((state) => state.carousels);

  return (
    <body id="home">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          {carousels.length > 0
            ? carousels[0].fileNames.map((_, index) => (
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to={index}
                  className={index === 0 ? " active" : ""}
                />
              ))
            : null}
        </ol>
        <div className="carousel-inner">
          {carousels.length > 0
            ? carousels[0].fileNames.map((slide, index) => (
                <div
                  className={"carousel-item" + (index === 0 ? " active" : "")}
                >
                  <img
                    src={process.env.REACT_APP_DOMAIN + `/uploads/${slide}`}
                    className="carousel-img d-block w-100"
                    alt="Slide 1"
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <Bounce left>
                      {" "}
                      <h5>Motor technology Solution</h5>
                    </Bounce>

                    <Bounce right>
                      <p>
                        Nous aimons ce que nous faisons et aurons beaucoup de
                        plaisir à vous accompagner dans la légende !
                      </p>
                    </Bounce>
                    <Bounce bottom>
                      <p>
                        <HashLink onClick={play} smooth to="/#produit">
                          Nos véhicules
                        </HashLink>
                      </p>
                    </Bounce>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </body>
  );
}
