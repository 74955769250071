import React, { useEffect, useState } from "react";
import "./listProduct.scoped.css";
import { Link } from "react-router-dom";
import { getCategories } from "./api/category";

export default function ListProduct({
  category,
  setcategory,
  setbrandName,
  setYear,
  year,
}) {
  const [isMobile, SetMobileStatus] = useState(window.innerWidth < 768);
  const [categories, setcategories] = useState([]);

  window.addEventListener("resize", function () {
    SetMobileStatus(window.innerWidth < 768);
  });
  const handleClear = () => {
    setbrandName("");
    setYear("");
    setcategory("");
  };
  useEffect(() => {
    const call = async () => {
      const value = await getCategories();
      console.log(value);
      if (value?.data?.categories) setcategories(value?.data?.categories);
    };
    call();
  }, []);

  return (
    <div className="w-100 d-flex flex-column align-items-center align-items-md-start">
      <div className="pt-4 pb-3"></div>
      <div className="card w-100">
        <article className="filter-group">
          <header className="card-header">
            {" "}
            <Link
              To="#"
              data-toggle="collapse"
              data-target="#collapse_aside2"
              data-abc="true"
              aria-expanded="false"
              className="collapsed"
            >
              {" "}
              <i className="icon-control fa fa-chevron-down" />
              <h6 className="title">Années </h6>
            </Link>{" "}
          </header>
          <div
            // className="filter-content"
            className={`filter-content ${
              isMobile ? "collapse" : "collapse show"
            }`}
            id="collapse_aside2"
          >
            <div className="card-body">
              {" "}
              <input
                type="range"
                className="custom-range"
                min="1950"
                max="2021"
                name=""
                onMouseUp={(e) => {
                  setYear(e.target.value);
                }}
              />
              <div className="form-row">
                <div className="form-group col-md-6">
                  {" "}
                  <label>{year}</label>{" "}
                </div>
                <div className="form-group text-right col-md-6"> </div>
              </div>{" "}
            </div>
          </div>
        </article>
        <article classNameName="filter-group">
          <header className="card-header">
            {" "}
            <Link
              To="#"
              data-toggle="collapse"
              data-target="#collapse_aside3"
              data-abc="true"
              aria-expanded="false"
              className="collapsed"
            >
              {" "}
              <i className="icon-control fa fa-chevron-down" />
              <h6 className="title">Categories </h6>
            </Link>{" "}
          </header>
          <div
            className="filter-content"
            
            className={isMobile ? "collapse" : "collapse show"}
            id="collapse_aside3"
          >
            <div className="card-body">
              {" "}
              {categories.map((item, i) => {
                return (
                  <label className="checkbox-btn" key={i}>
                    {" "}
                    <input
                      onClick={(e) => setcategory(e.target.value)}
                      type="checkbox"
                      value={item._id}
                    />{" "}
                    <span
                      style={{
                        backgroundColor:
                          category === item._id ? "grey" : "grey",
                        color: "white",
                      }}
                      className="btn btn-light"
                    >
                      {" "}
                      {item.category}{" "}
                    </span>{" "}
                  </label>
                );
              })}
            </div>
          </div>
        </article>
        
        <Link
            onClick={handleClear}
            className="highlight-button btn btn-medium button xs-margin-bottom-five"
            data-abc="true"
          >
            Réinitialiser tous les filtres
          </Link>
      </div>
    </div>
  );
}
